import $ from 'jquery'
window.jQuery = $;
window.$ = $;

$( document ).on('turbo:load', function() {
  const form = document.forms.item(0);
  const hiddenInput = document.getElementById('g-recaptcha-response-data-contact')
  form.addEventListener('submit', async function(e) {
    e.preventDefault();
    // prevent default submit behaviour
    // get values from FORM
    var travel_agency = $("input#travel_agency").val();
    var name = $("input#name").val();
    var phone = $("input#phone").val();
    var email = $("input#mail").val();
    var message = $("textarea#message").val();
    var firstName = name; // For Success/Failure Message
    const response = await window.executeRecaptchaForContactAsync()
    hiddenInput.value = response
    // Check for white space in name for Success/Fail message
    if (firstName.indexOf(' ') >= 0) {
      firstName = name.split(' ').slice(0, -1).join(' ');
    }
    var data = $("#contact_form").serializeArray();
    var $this = $("#sendMessageButton");
    $this.prop("disabled", true); // Disable submit button until AJAX call is complete to prevent duplicate messages
    $.ajax({
      url: "/contact",
      type: "POST",
      data: data,
      cache: false,
      success: function() {
        // Success message
        $('#success').html("<div class='alert alert-success'>");
        $('#success > .alert-success').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
          .append("</button>");
        $('#success > .alert-success')
          .append("<strong>Su mensaje fue enviado, en breve le responderemos. Gracias. </strong>");
        $('#success > .alert-success')
          .append('</div>');
        //clear all fields
        $('#contact_form').trigger("reset");
      },
      error: function(e) {
        // Fail message
        $('#success').html("<div class='alert alert-danger'>");
        $('#success > .alert-danger').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
          .append("</button>");
        if (e.status == 400 ) {
          payload = jQuery.parseJSON(e.responseText)
          $('#success > .alert-danger').append('<div><strong>' + payload.error + '</strong></div>')
        }
        else {
          $('#success > .alert-danger').append($("<strong>").text("Por favor " + firstName + ", volvé a probar en unos instantes."));
          $('#success > .alert-danger').append('</div>');
        }
        //clear all fields
        $('#contact_form').trigger("reset");
      },
      complete: function() {
        setTimeout(function() {
          $this.prop("disabled", false); // Re-enable submit button when AJAX call is complete
        }, 1000);
        window.executeRecaptchaForContact()        
      }
    });
  })
});

